import {
  SET_CUSTOMER,
  SET_ORDER_SETTINGS,
  SET_RESELLER,
  VALIDATE_ORDER_CUSTOMER,
  SET_BASKET,
  VALIDATE_BASKET,
  SET_NOTE,
  SET_TOTAL_PRICE,
  SET_CURRENCY,
  SET_ASSOCIATED_DEAL,
  SET_ASSOCIATED_DEALS,
  SET_ORDER_DEAL_TRESHOLD,
  SET_SHOW_ASSOCIATED_DEALS_SELECT,
  SET_SHOW_ASSOCIATED_DEALS_MSG,
  SET_IS_NOTE_REQUIRED,
  CLEAN,
} from "../actionTypes";
import { checkDecimal8 } from "../../services/utils";

//return - boolean | null
const validateOrderCustomer = (customer) => {
  if (customer === "setNull") {
    return null;
  }

  if (customer) {
    return true;
  } else {
    return false;
  }
};

const validate = (obj) =>
  obj
    ? Object.entries(obj).reduce(
        (acc, [key, item]) => {
          const _item = Object.entries(item).reduce(
            (acc, [key, item]) => {
              if (item?.error !== undefined) {
                if (!item.value && item.required) {
                  item.error = "Required field";
                } else if ((item.min || item.min === 0) && (item.max || item.max === 0)) {
                  if (item.value && (item.value < item.min || item.value > item.max)) {
                    item.error = `Value must be between ${item.min} and ${item.max}.`;
                  } else {
                    item.error = false;
                  }
                } else if (item.max || item.max === 0) {
                  if (item.value && item.value > item.max) {
                    item.error = `Max value is ${item.max}.`;
                  } else {
                    item.error = false;
                  }
                } else if (item.min || item.min === 0) {
                  if (item.value && item.value < item.min) {
                    item.error = `Min value is ${item.min}.`;
                  } else {
                    item.error = false;
                  }
                } else if (key === "discount") {
                  if (item.value && item.value > 100) {
                    item.error = `Max value is 100 %`;
                  } else if (item.value && !checkDecimal8(item.value)) {
                    item.error = "Invalid format";
                  } else {
                    item.error = false;
                  }
                } else {
                  item.error = false;
                }
              }
              return acc;
            },
            { ...item }
          );

          return { ...acc, [key]: _item };
        },
        { ...obj }
      )
    : null;

const order = (state = null, action) => {
  switch (action.type) {
    case "order/" + CLEAN: {
      return {
        orderSettings: state.orderSettings,
      };
    }
    case "order/" + SET_IS_NOTE_REQUIRED: {
      return {
        ...state,
        isNoteRequired: action.required,
      };
    }
    case "order/" + SET_SHOW_ASSOCIATED_DEALS_MSG: {
      return {
        ...state,
        showAssociatedDealsMsg: action.show,
      };
    }
    case "order/" + SET_SHOW_ASSOCIATED_DEALS_SELECT: {
      return {
        ...state,
        showAssociatedDealsSelect: action.show,
      };
    }
    case "order/" + SET_ORDER_DEAL_TRESHOLD: {
      return {
        ...state,
        orderDealTreshold: action.treshold,
      };
    }
    case "order/" + SET_ASSOCIATED_DEALS: {
      return {
        ...state,
        associatedDeals: action.deals,
      };
    }
    case "order/" + SET_ASSOCIATED_DEAL: {
      return {
        ...state,
        associatedDeal: action.deal,
      };
    }
    case "order/" + SET_CURRENCY: {
      return {
        ...state,
        currency: action.currency,
      };
    }
    case "order/" + SET_TOTAL_PRICE: {
      return {
        ...state,
        totalPrice: action.price,
      };
    }
    case "order/" + SET_NOTE: {
      return {
        ...state,
        note: action.note,
      };
    }
    case "order/" + VALIDATE_BASKET: {
      return {
        ...state,
        isBasketValid: validate(action.basket),
      };
    }
    case "order/" + SET_BASKET: {
      return {
        ...state,
        basket: action.basket,
      };
    }
    case "order/" + VALIDATE_ORDER_CUSTOMER: {
      return {
        ...state,
        isOrderCustomerValid: validateOrderCustomer(action.customer),
      };
    }
    case "order/" + SET_ORDER_SETTINGS: {
      return {
        ...state,
        orderSettings: action.orderSettings,
      };
    }
    case "order/" + SET_CUSTOMER: {
      return {
        ...state,
        customer: action.customer,
      };
    }
    case "order/" + SET_RESELLER: {
      return {
        ...state,
        ...action.reseller,
      };
    }
    default: {
      return state;
    }
  }
};

export default order;
